exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-candidate-views-2022-general-election-js": () => import("./../../../src/pages/candidate-views/2022-general-election.js" /* webpackChunkName: "component---src-pages-candidate-views-2022-general-election-js" */),
  "component---src-pages-candidate-views-2023-general-candidate-views-js": () => import("./../../../src/pages/candidate-views/2023GeneralCandidateViews.js" /* webpackChunkName: "component---src-pages-candidate-views-2023-general-candidate-views-js" */),
  "component---src-pages-candidate-views-2023-primary-js": () => import("./../../../src/pages/candidate-views/2023-primary.js" /* webpackChunkName: "component---src-pages-candidate-views-2023-primary-js" */),
  "component---src-pages-candidate-views-general-election-js": () => import("./../../../src/pages/candidate-views/GeneralElection.js" /* webpackChunkName: "component---src-pages-candidate-views-general-election-js" */),
  "component---src-pages-candidate-views-js": () => import("./../../../src/pages/candidate-views.js" /* webpackChunkName: "component---src-pages-candidate-views-js" */),
  "component---src-pages-candidate-views-primary-election-js": () => import("./../../../src/pages/candidate-views/PrimaryElection.js" /* webpackChunkName: "component---src-pages-candidate-views-primary-election-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-guide-2022-general-election-js": () => import("./../../../src/pages/guide/2022-general-election.js" /* webpackChunkName: "component---src-pages-guide-2022-general-election-js" */),
  "component---src-pages-guide-2023-general-green-voters-guide-js": () => import("./../../../src/pages/guide/2023GeneralGreenVotersGuide.js" /* webpackChunkName: "component---src-pages-guide-2023-general-green-voters-guide-js" */),
  "component---src-pages-guide-2023-primary-js": () => import("./../../../src/pages/guide/2023-primary.js" /* webpackChunkName: "component---src-pages-guide-2023-primary-js" */),
  "component---src-pages-guide-2024-primary-js": () => import("./../../../src/pages/guide/2024-primary.js" /* webpackChunkName: "component---src-pages-guide-2024-primary-js" */),
  "component---src-pages-guide-general-election-js": () => import("./../../../src/pages/guide/GeneralElection.js" /* webpackChunkName: "component---src-pages-guide-general-election-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-guide-primary-election-js": () => import("./../../../src/pages/guide/PrimaryElection.js" /* webpackChunkName: "component---src-pages-guide-primary-election-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

